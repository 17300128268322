body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--rs-text-quartenary) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --primary-color: #50bf8d;
    --secondary-color: #042234;
}


/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}